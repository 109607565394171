@import "react-quill/dist/quill.snow.css";


body, html {
    -ms-content-zooming: none
}

.error-message, .navbar {
    justify-content: space-between
}

.mobile-hamb:hover, .mobile-navbar__close:hover, .navbar__buttons__sign-in:hover {
    color: #1a1a1a
}

.hero-content__text h4, .navbar__buttons, .navbar__links a {
    font-family: Rubik, sans-serif
}

*, .loader {
    box-sizing: border-box
}

.footer-content__1, .footer-content__2, .navbar__links {
    list-style: none
}

* {
    margin: 0;
    padding: 0;
    scrollbar-width: thin;
    scrollbar-color: #FDC323 #ffffff
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    overflow-x: none
}

body {
    background-color: #fff;
    font-family: Poppins, sans-serif;
    position: relative;
    overflow-x: hidden;
    overflow-x: none
}

.container {
    max-width: 133rem !important;
    margin: 0 auto !important;
    padding: 0 2.5rem;
}

::-webkit-scrollbar {
    width: 5px
}

::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #b90000
}

::-webkit-scrollbar-track:hover {
    background-color: #b90000
}

::-webkit-scrollbar-track:active {
    background-color: #b90000
}

::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #b90000
}

::-webkit-scrollbar-thumb:hover {
    background-color: #b90000
}

::-webkit-scrollbar-thumb:active {
    background-color: #b90000
}

.navbar__content {
    max-width: 133rem;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffff;
    z-index: 99999;
    margin: 0 auto

}

.navbar {
    display: flex;
    align-items: center;
    padding: 10px 50px;
    background-color: #ffff;
    z-index: 99999;
    margin: 0 auto

}

.navbar__img {
   width: 170px;
   height: auto;
}


.navbar__img img {
    width: 120px;  
    height: auto;  
    object-fit: contain;  
     
}


.navbar__links {
    display: flex;
    gap: 2.1rem
}

.navbar__links a {
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    transition: .3s
}

.navbar__links a:hover {
    color: #fdc323
}

.navbar__buttons {
    display: flex;
    gap: 2.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    align-items: center
}

.navbar__buttons__sign-in {
    color: #010103;
    cursor: pointer;
    transition: .3s
}

.navbar__buttons__register {
    background-color: #1a1a1a;
    color: #fff;
    padding: 1.5rem 3rem;
    border-radius: 3px;
    transition: .3s
}

.navbar__buttons__register:hover {
    box-shadow: 0 10px 15px 0 rgba(255, 83, 48, .5);
    background-color: #fa4226
}

.navbar__buttons a {
    text-decoration: none
}

.mobile-hamb {
    font-size: 2.8rem;
    display: none;
    cursor: pointer;
    transition: .3s
}

.mobile-navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    background-color: #fff;
    z-index: 999999;
    justify-content: center;
    align-items: center;
    transition: .5s ease-in-out
}

.mobile-navbar__close {
    font-size: 3rem;
    position: absolute;
    top: 3.5rem;
    right: 3.5rem;
    cursor: pointer;
    transition: .3s
}

.mobile-navbar__links {
    display: flex;
    flex-direction: column;
    list-style: none;
    font-size: 2.3rem;
    gap: 3rem;
    text-align: center
}

.mobile-navbar__links li a {
    text-decoration: none;
    color: #010103;
    font-weight: 500;
    transition: .3s
}

.book-content__box .box-form b, .book-content__box .box-form__car-time label i, .book-content__box .box-form__car-type label i, .hero-content__text h1 span, .mobile-navbar__links li a:hover {
    color: #1a1a1a
}

.open-nav {
    left: 0
}

.hero-section {
    width: 100%;
    height: 97vh;
    background-color: #f8f8f8;
    position: relative
}

.bg-shape {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1
}

.hero-content {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative
}

.hero-content__text {
    display: flex;
    flex-direction: column;
    z-index: 3;
    max-width: 50rem;
    margin-top: 5rem
}

.hero-content__text h4 {
    font-size: 2.2rem;
    color: #010103
}

.hero-content__text h1 {
    font-size: 3.2rem;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    color: #010103;
    line-height: 1.2;
    margin-top: 1rem;
    margin-bottom: 2.3rem
}

.hero-content__text p {
    font-size: 1.2rem;
    font-family: Rubik, sans-serif;
    line-height: 1.6;
    color: #706f7b;
    margin-bottom: 4rem
}

.hero-content__text__btns {
    display: flex;
    gap: 2rem;
    font-size: 1.2rem;
    font-family: Rubik, sans-serif
}

.hero-content__text__btns a {
    text-decoration: none;
    color: #fff;
    font-weight: 700
}

.hero-content__text__btns__book-ride {
    background-color: #1a1a1a;
    padding: 1.8rem 4rem;
    border-radius: .3rem;
    transition: .3s
}

@media (max-width:450px) {
    .hero-content__text__btns {
        flex-direction: column
    }

    .hero-content__text__btns__book-ride {
        padding: 1.8rem 8rem
    }
}

.hero-content__text__btns__book-ride:hover {
    background-color: #e91e22;
    color: #fff
}

.hero-content__text__btns__learn-more {
    background-color: #010103;
    padding: 1.8rem 3rem;
    border-radius: .3rem;
    border: 2px solid #010103;
    transition: .3s
}

.hero-content__text__btns__learn-more:hover {
    background-color: transparent;
    color: #010103
}

.hero-content__car-img {
    z-index: 2;
    position: absolute;
    right: 0
}

.scroll-up {
    position: fixed;
    font-size: 2.5rem;
    color: #fff;
    background-color: #1a1a1a;
    border: 3px solid #fff;
    width: 2rem;
    height: 2rem;
    bottom: 5rem;
    right: 5rem;
    z-index: 20;
    display: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 2rem;
    cursor: pointer
}

.show-scroll {
    display: flex
}

.book-section {
    position: relative;
    background-image: url("../images/book-car/josh-berquist-_4sWbzH5fp8-unsplash.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 0 100px
}

.book-content-bg {
    position: relative;
    background-color: transparent;
    padding: 9rem 4rem 5rem;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
    backdrop-filter: blur(40.1px);
    -webkit-backdrop-filter: blur(10.1px);
    border: 5px solid #fdc323
}

.book-content {
    margin: 0 auto
}

.book-content__box {
    width: 100%;
    padding: 7rem;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .1);
    height: auto;
    position: relative;
    z-index: 4;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    color: #010103;
    text-align: center
}

.book-car-title {
    position: absolute;
    top: -3px;
    right: 0;
    left: 0
}

.booking-modal, .yellow-box {
    left: 50%;
    transform: translate(-50%, -50%)
}

.book-car-title span {
    font-size: 1.8rem;
    font-weight: 600;
    padding: 0 50px 10px;
    text-align: center;
    color: #090808;
    background-color: #fdc323;
    border-radius: 0 0 40px 40px
}

.book-content__box .box-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 3rem
}

@media (max-width:1000px) {
    .navbar__buttons, .navbar__links {
        display: none
    }

    .mobile-hamb {
        display: flex
    }

    .book-content__box .box-form {
        grid-template-columns: 1fr 1fr
    }
}

@media (max-width:630px) {
    .book-content__box .box-form {
        grid-template-columns: 1fr
    }
}

.book-content__box .box-form__car-type, .choose-container, .info-form, .pick-container, .plan-container, .testimonials-content {
    display: flex;
    flex-direction: column
}

.book-content__box .box-form__car-type label {
    font-weight: 600;
    margin-bottom: 5px;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    
}

.book-content__box .box-form__car-type select {
    color: #ababab;
    font-family: Rubik, sans-serif;
    border-radius: 10px;
    font-weight: 400;
    outline: 0;
    border: 1px solid #eee
}

.book-content__box .box-form__car-time {
    display: flex;
    flex-direction: column;
    position: relative
}

.book-content__box .box-form__car-time input {
    color: #ffffff;
    font-weight: 400;
    border: 1px solid #ffffff;
    height: 42px;
    font-size: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: text;
    outline: 0 !important;
    position: relative;
    -webkit-transition: .1s;
    transition: .1s;
    background-color: transparent !important;
    border-radius: 4px;
    box-sizing: border-box
}


.book-content__box .box-form__car-time input ::placeholder{
    color: #fff;
}

/* Target the control (input box) */
.custom-select__control {
    color: #ffffff;
    font-weight: 400;
    border: 1px solid #ffffff;
    height: 42px;
    font-size: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: text;
    outline: 0 !important;
    position: relative;
    -webkit-transition: .1s;
    transition: .1s;
    background-color: transparent !important;
    border-radius: 4px;
    box-sizing: border-box
  }
 

.book-content__box .box-form__car-time label {
    font-weight: 600;
    margin-bottom: 5px;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
}

.book-content__box .box-form__car-time ::-webkit-datetime-edit {
    padding: 1.3rem
}

.book-content__box .box-form__car-time ::-webkit-calendar-picker-indicator {
    cursor: pointer;
    font-size: 1.6rem
}

.search-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center
}

.book-content__box button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: none;
    background: #fdc323;
    color: #000;
    font-family: inherit;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    transition: .4s;
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;
    text-transform: uppercase
}

.booking-done, .error-message {
    margin-bottom: 1.8rem;
    margin-top: -1rem;
    border: 1px solid transparent;
    font-weight: 500;
    display: none
}

.book-content__box button:hover {
    background-color: #c9fa3d;
    color: #333;
    font-weight: 600
}

.error-message {
    color: #721c24;
    background-color: #fdeded;
    align-items: center
}

.booking-done {
    color: #2a6817;
    background-color: #c3fabe;
    padding: 1rem 1.4rem;
    border-radius: .8rem;
    font-size: 1.6rem;
    justify-content: space-between;
    align-items: center
}

.booking-done i {
    cursor: pointer
}

.active-modal {
    opacity: 1 !important;
    display: flex !important
}

.booking-modal, .modal-overlay {
    opacity: 0;
    display: none;
    position: fixed
}

.modal-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    z-index: 999999999;
    top: 0;
    right: 0
}

.booking-modal {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 999999999999;
    top: 54%;
    width: 83rem;
    height: 100vh;
    border: 2px solid #fff;
    background-color: #fff;
    padding-right: 2px;
    color: #010103
}

.pick-description__table__col, .pick-description__table__col span:first-child {
    border-right: 2px solid #706f7b
}

@media (max-width:600px) {
    .booking-modal {
        top: 50%
    }
}

.booking-modal__title {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    background-color: #1a1a1a;
    color: #fff;
    align-items: center
}

.booking-modal__title h2 {
    font-size: 2rem;
    text-transform: uppercase
}

.booking-modal__title i {
    font-size: 2.5rem;
    color: rgba(255, 255, 255, .919);
    cursor: pointer
}

.booking-modal__title i:hover, .footer-bottom a {
    color: #fff
}

.booking-modal__message {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem 3rem;
    background-color: #ffeae6
}

.booking-modal__message h4 {
    font-size: 1.2rem;
    font-weight: 700;
    color: #1a1a1a
}

.booking-modal__message h4 i {
    font-size: 2.5rem
}

.booking-modal__message p {
    font-size: 1rem;
    color: #777;
    font-weight: 500;
    line-height: 1.6
}

.booking-modal__car-info {
    background-color: #fff;
    padding: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid rgba(119, 119, 119, .6235294118)
}

.booking-modal__car-info__dates {
    display: flex;
    flex-direction: column;
    gap: 2rem
}

.booking-modal__car-info__dates h5 {
    font-size: 1rem;
    color: #1a1a1a
}

.booking-modal__car-info__dates span {
    display: flex;
    gap: 1rem
}

.booking-modal__car-info__dates span i {
    font-size: 1.8rem;
    padding-top: .2rem;
    color: #777
}

.booking-modal__car-info__dates span h6 {
    font-size: 1rem;
    margin-bottom: .2rem
}

.booking-modal__car-info__dates span p {
    font-size: 1rem;
    color: #777
}

.booking-modal__car-info__model {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 500px;
    height: 400px
}

.booking-modal__car-info__model h5 {
    font-size: 1rem;
    color: #1a1a1a
}

.booking-modal__car-info__model h5 span {
    color: #010103
}

.booking-modal__car-info__model img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.booking-modal__person-info {
    padding: 3rem;
    background-color: #fff;
    display: flex;
    flex-direction: column
}

.booking-modal__person-info h4 {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #1a1a1a;
    margin-bottom: 2rem
}

.input-time {
    text-align: center;
    width: 8rem;
    font-size: 14px;
    cursor: pointer
}

.info-form__checkbox {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: #565454;
    margin-top: 2rem;
    margin-bottom: 3rem
}

.info-form__checkbox input {
    width: 1.6rem;
    height: 1.6rem
}

.info-form__1col {
    grid-template-columns: 1fr !important
}

.info-form__1col, .info-form__2col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 1rem 0
}

.info-form__1col span, .info-form__2col span {
    display: flex;
    flex-direction: column;
    gap: .4rem
}

.info-form__1col span label, .info-form__2col span label {
    font-size: 1rem;
    font-weight: 500;
    color: #777
}

.info-form__1col span label b, .info-form__2col span label b {
    color: #1a1a1a
}

.info-form__1col span input, .info-form__2col span input {
    padding: 14px 15px;
    background-color: #dbdbdb;
    color: #555;
    font-weight: 500;
    outline: 0;
    border: none
}

.reserve-button {
    background-color: #dbdbdb;
    margin: 0 -3rem;
    padding: 3rem;
    text-align: center
}

.reserve-button button {
    color: #fff;
    font-weight: 700;
    background-color: #1a1a1a;
    border: 1px solid #ff0;
    padding: 1.2rem 2.9rem;
    cursor: pointer;
    transition: .2s
}

.reserve-button button:hover {
    background-color: #ffd000
}

.dates-div {
    display: flex;
    flex-direction: column;
    gap: 3.2rem
}

.plan-section {
    background-color: #fff;
    margin-bottom: 100px;

}

.plan-container__title {
    margin: 0 auto;
    text-align: center;
    color: #010103
}

.plan-container__title h3 {
    font-size: 1rem;
    font-family: Rubik, sans-serif;
    font-weight: 400;
    text-transform: uppercase
}

.plan-container__title h2 {
    font-size: 2rem;
    font-family: Poppins, sans-serif;
    text-transform: uppercase
}

.plan-container__boxes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    margin-top: 3.7rem;
    padding: 0 3rem
}

@media (max-width:1021px) {
    .plan-container__boxes {
        grid-template-columns: 1fr 1fr;
        row-gap: 2rem
    }
}

.plan-container__boxes__box {
    text-align: center;
    padding: 1rem 4rem
}

@media (max-width:500px) {
    .plan-container__boxes__box {
        padding: 1rem
    }
}

.plan-container__boxes__box img {
    width: 12rem;
    height: auto
}

.plan-container__boxes__box h3 {
    font-size: 1.7rem;
    margin-bottom: 1rem
}

.pick-container__title h3, .pick-container__title p, .plan-container__boxes__box p {
    font-size: 1.2rem;
    font-family: Rubik, sans-serif
}

.plan-container__boxes__box p {
    color: #706f7b;
    line-height: 1.43
}

.pick-section {
    padding: 10rem 0
}

.pick-container__title {
    margin: 0 auto 5rem;
    text-align: center;
    color: #010103;
    max-width: 50rem
}

.pick-container__title p {
    color: #706f7b;
    line-height: 1.5
}

.pick-container__title h3 {
    font-weight: 400;
    text-transform: capitalize
}

.cta-btn, .pick-box button {
    font-weight: 600;
    font-family: Poppins, sans-serif
}

.pick-container__title h2 {
    font-size: 2.2rem;
    font-family: Poppins, sans-serif;
    margin: .5rem 0 1rem
}

.pick-container__car-content {
    display: flex;
    justify-content: space-between;
    gap: 1rem
}

@media (max-width:1050px) {
    .pick-container__car-content {
        flex-direction: column;
        gap: 5rem
    }
}

.pick-box {
    display: flex;
    flex-direction: column;
    gap: .7rem
}

.pick-box button {
    font-size: 2rem;
    border: none;
    cursor: pointer;
    padding: 1.5rem 2.5rem;
    background-color: #e9e9e9;
    transition: .2s;
    text-align: left
}

.cta-btn, .text-container__left a {
    text-decoration: none;
    transition: .3s
}

.active-question, .pick-box button:hover {
    background-color: #1a1a1a;
    color: #fff
}

.pick-car {
    width: 50rem;
    position: relative
}

.pick-car img {
    width: 100%;
    margin-top: 6rem
}

.pick-description {
    width: 25rem
}

.cta-btn, .pick-description__price {
    width: 100%;
    color: #fff;
    background-color: #1a1a1a
}

.pick-description__price {
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: .3rem 1.9rem;
    white-space: nowrap
}

.pick-description__price span {
    font-size: 2.8rem;
    font-weight: 700
}

.pick-description__table {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    font-size: 1.4rem
}

.pick-description__table__col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    grid-template-rows: auto;
    padding: .9rem .5rem;
    border-bottom: 2px solid #706f7b;
    border-left: 2px solid #706f7b
}

.cta-btn {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 1.4rem;
    box-shadow: 6px 6px 0 #efe9e9
}

.about-main__text h3, .about-main__text p, .all-testimonials__box__name__profile p, .contact-div__text p, .download-text p, .faq-box__answer, .footer-content__2 input, .testimonials-content__title h4, .testimonials-content__title p, .text-container__left h4, .text-container__left p, .text-container__right__box__text p {
    font-family: Rubik, sans-serif
}

.cta-btn:hover {
    background-color: #e9381d
}

.box-cars {
    gap: 11rem;
    display: flex;
    justify-content: space-between
}

@media (max-width:1200px) {
    .box-cars {
        gap: 2rem
    }
}

.colored-button {
    background-color: #1a1a1a !important;
    color: #fff
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid rgba(0, 0, 0, .2588235294);
    border-bottom-color: #ff3d00;
    border-radius: 50%;
    display: inline-block;
    animation: 1s linear infinite rotation;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 40%;
    right: 43%
}

@keyframes rotation {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.banner-section {
    width: 100%;
    height: auto;
    display: flex;
    background-image: linear-gradient(to top, #cfd9df 0, #e2ebf0 100%);
    padding: 8rem 0;
    text-align: center
}

.banner-section-2 {
    background-image: url('../images/hero/hero-11.webp');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center; /* Center the card horizontally */
    align-items: center; /* Center the card vertically */
    min-height: 60vh;
    padding: 2rem;
    color: #fff;
}

.banner-section-card-2{
    background-color: #fec601;
    border-radius: 16px;
    padding: 2rem;
    color: #000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: left;
}

.info-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.info-list {
    list-style-type: none; /* Bullet points */

}

.info-list li {
    margin: 1.5rem 0;
    font-size: 1.5rem;
}

.info-list li strong {
    color: #000; /* Accent color for key phrases */
}



.banner-content {
    color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center
}

.banner-content__text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem
}

.banner-content__text h2 {
    font-size: 3.5rem;
    line-height: 1.3;
    color: #000
}

.banner-content__text p {
    font-size: 2.1rem;
    color: #000;
    text-transform: uppercase
}

.banner-content-second-text p {
    margin: 0;
    position: relative
}

.banner-content-second-text p:not(:last-child)::after {
    content: '|';
    position: absolute;
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
    color: #5b5a5a;
    font-weight: 200;
    margin-left: 8px
}

.banner-content__text span {
    color: #000;
    font-weight: 700
}

.choose-section {
    background-color: #fff;
    padding: 2rem 0 10rem;
    background-position: -225px 255px;
    background-size: cover;
    background-repeat: no-repeat
}

.choose-container__img {
    width: 90%;
    height: auto;
    margin: 0 auto
}

.text-container {
    display: flex;
    justify-content: space-around;
    margin-top: 3rem;
    width: 100%
}

.text-container__left {
    text-align: left;
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    color: #010103
}

.text-container__left h4 {
    font-size: 2.2rem;
    margin-bottom: .7rem;
    font-weight: 600
}

.text-container__left h2 {
    font-size: 4.2rem;
    line-height: 1.2;
    margin-bottom: 2rem
}

.text-container__left p {
    font-size: 1.6rem;
    line-height: 1.5;
    color: #706f7b;
    margin-bottom: 3.3rem
}

.text-container__left a {
    color: #fff;
    font-weight: 700;
    background-color: #1a1a1a;
    padding: 1.5rem 2.5rem;
    border-radius: .3rem;
    border: 2px solid #1a1a1a;
    font-size: 1.6rem;
    width: -moz-fit-content;
    width: fit-content
}

.contact-div__text a, .footer-content__1 li a, .footer-content__2 a, .hero-pages__text p a {
    text-decoration: none;
    transition: .2s
}

.text-container__left a:hover {
    box-shadow: 0 10px 15px 0 rgba(255, 83, 48, .6);
    background-color: #fa4226
}

.text-container__right {
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
    max-width: 44rem
}

.text-container__right__box {
    display: flex
}

.text-container__right__box img {
    width: 11rem;
    height: 11rem;
    margin-right: 1.1rem
}

.text-container__right__box__text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center
}

.text-container__right__box__text h4 {
    font-size: 2.4rem
}

.text-container__right__box__text p {
    font-size: 1.6rem;
    color: #706f7b;
    line-height: 1.3
}

.testimonials-section {
    background-color: #f8f8f8;
    padding: 5rem 0;
    color: #010103
}

.footer-content__1 li a:hover, .quotes-icon {
    color: #1a1a1a
}

.testimonials-content__title {
    display: flex;
    flex-direction: column;
    margin: 0 auto 5rem;
    text-align: center;
    max-width: 70rem
}

.testimonials-content__title h4 {
    font-size: 1.2rem;
    font-weight: 500
}

.testimonials-content__title h2 {
    font-size: 2.2rem;
    margin-bottom: 1.4rem
}

.testimonials-content__title p {
    font-size: 1.2rem;
    color: #706f7b;
    line-height: 1.4
}

.all-testimonials {
    display: flex !important;
    gap: 3rem;
    width: 100%;
    justify-content: center;
    padding: 3rem
}

.all-testimonials__box__name, .faq-content {
    display: flex
}

.all-testimonials__box {
    background-color: #fff;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, .08);
    width: 54rem;
    padding: 5.5rem;
    position: relative
}

@media (max-width:1000px) {
    .text-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 5.5rem
    }

    .text-container__left {
        align-items: center;
        text-align: center
    }

    .all-testimonials {
        padding: 0
    }

    .all-testimonials__box {
        padding: 5rem 3rem
    }
}

.all-testimonials__box p {
    font-size: 1.2rem;
    font-weight: 500
}

.all-testimonials__box__name__profile {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-top: 3rem
}

.all-testimonials__box__name__profile img {
    width: 7rem;
    height: 7rem;
    border-radius: 50%
}

.all-testimonials__box__name__profile h4 {
    font-size: 1.2rem
}

.all-testimonials__box__name__profile p {
    font-size: 1rem;
    font-weight: 400
}

.quotes-icon {
    font-size: 6.2rem;
    position: absolute;
    bottom: 33px;
    right: 60px
}

@media (max-width:470px) {
    .quotes-icon {
        display: none
    }
}

@media (max-width:900px) {
    .box-2 {
        display: none
    }
}

.faq-section {
    background-image: url("/src/images/faq/car.png");
    padding: 6rem 0;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 0 70%
}

.download-section, .hero-pages {
    background-size: cover;
    background-repeat: no-repeat
}

.faq-content {
    flex-direction: column;
    color: #010103
}

.all-questions, .faq-content__title {
    flex-direction: column;
    display: flex
}

.faq-content__title {
    margin: 0 auto;
    text-align: center;
    max-width: 80rem;
    line-height: 1.5
}

.faq-content__title h5 {
    font-size: 1.2rem
}

.faq-content__title h2 {
    font-size: 2.2rem;
    margin-bottom: 1.7rem
}

.faq-content__title p {
    font-size: 1.2rem;
    color: #706f7b
}

.all-questions {
    align-items: center;
    margin-top: 2rem
}

.faq-box {
    display: flex;
    flex-direction: column;
    color: #010103;
    background-color: #fff;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .1);
    width: 80rem;
    cursor: pointer
}

@media (max-width:850px) {
    .faq-box {
        width: 100%
    }
}

.faq-box__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1);
    padding: 1.8rem 4.5rem;
    transition: .15s
}

.faq-box__question p {
    font-size: 1.2rem;
    font-weight: 500
}

.faq-box__question i {
    font-size: 2rem
}

.faq-box__answer {
    font-size: 1rem;
    color: #706f7b;
    line-height: 1.7;
    max-height: 0;
    overflow: hidden;
    transition: .4s;
    padding: 0 4.5rem
}

.active-answer {
    max-height: 20rem;
    padding: 2rem 2rem 2rem 5rem;
    transition: .4s
}

@media (max-width:550px) {
    .info-list li { margin: 1.5rem 0; font-size: 1.3rem; }
    .banner-content__text h2 {
        font-size: 2.2rem
    }

    .banner-content__text p {
        font-size: 1.5rem
    }

    .choose-container__img {
        width: 100%
    }

    .text-container__right__box {
        flex-direction: column;
        align-items: center
    }

    .active-answer {
        max-height: 30rem
    }
}

@media (max-width:420px) {
    .info-list li { margin: 1.5rem 0; font-size: 1.3rem; }
    .active-answer {
        max-height: 55rem;
        padding: 2rem 2rem ;
        transition: .4s;
    }
}

.download-section {
    background-color: #f8f8f8;
    background-position: top center;
    width: 100%;
    height: auto;
    padding: 10rem 0
}

.download-text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 55rem;
    text-align: left
}

.download-text h2 {
    font-size: 4.2rem;
    color: #010103
}

.download-text p {
    font-size: 1.6rem;
    color: #706f7b;
    line-height: 1.5
}

.download-text__btns {
    display: flex;
    gap: 3rem;
    margin-top: 2rem
}

@media (max-width:700px) {
    .pick-car {
        width: 100%
    }

    .box-cars {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5rem
    }

    .download-section {
        background-image: none
    }

    .download-text {
        text-align: center;
        margin: 0 auto
    }

    .download-text__btns {
        justify-content: center
    }
}

.about-main__text, .hero-pages__text {
    flex-direction: column;
    color: #010103
}

.download-text__btns img {
    width: 40%;
    cursor: pointer
}

@media (max-width:550px) {
    .info-list li { margin: 1.5rem 0; font-size: 1.3rem; }
    .download-text__btns {
        flex-direction: column;
        align-items: center
    }

    .download-text__btns img {
        width: 22rem
    }
}

footer {
    background-color: #fec601;
    padding: 100px 40px
}

.footer-bottom {
    background-color: #1a1a1a;
    color: #fff;
    text-align: center;
    font-size: small;
    padding: 20px
}

.footer-content {
    display: grid;
    color: #2d2d2d;
    grid-template-columns: 21fr 21fr 21fr;
    gap: 3rem;
    text-align: left
}

@media (max-width:1100px) {
    .footer-content {
        grid-template-columns: 1fr 1fr
    }

    .footer-content__2 input {
        width: 100%
    }
}

.footer-content__1 li a {
    color: #010103
}

.footer-content__1 li:first-child {
    font-size: 1.2rem;
   
}

.footer-content__1 li:first-child span {
    font-weight: 700
}

.footer-content__1 li:nth-child(2) {
    font-size: 1rem;
    color: #000;
    line-height: 1.7;
    margin-bottom: 3rem;
    font-weight: 500
}

.footer-content__2 a:hover, .hero-pages__text p a:hover {
    color: #1a1a1a
}

.footer-content__1 li:nth-child(3), .footer-content__1 li:nth-child(4) {
    font-size: 1.2rem;
    font-family: Rubik, sans-serif;
    font-weight: 500;
    margin-bottom: 1rem
}

@media (max-width:650px) {
    .booking-modal__car-info {
        grid-template-columns: 1fr;
        text-align: center
    }

    .booking-modal__car-info__dates span {
        grid-template-columns: 1fr;
        text-align: center;
        justify-content: center
    }

    .booking-modal__car-info__dates span i {
        display: none
    }

    .booking-modal__car-info__model {
        margin-top: 3.5rem
    }

    .footer-content, .info-form__1col, .info-form__2col {
        grid-template-columns: 1fr
    }

    .footer-content__1, .footer-content__2, .reserve-button {
        text-align: center
    }
}

.footer-content__2 a {
    color: #010103
}

.footer-content__2 li {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 500
}

.footer-content__2 li:first-child {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    font-family: Poppins, sans-serif;
    cursor: auto
}

.footer-content__2 input {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    background-color: #ececec;
    border-width: 1px;
    border-color: transparent;
    width: 100%;
    padding: 10px 20px;
    outline: 0;
    margin-top: 1rem
}

.submit-email {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    background-color: #1a1a1a;
    padding: 15px 20px;
    border-radius: .3rem;
    box-shadow: 0 10px 15px 0 rgba(255, 83, 48, .25);
    transition: .3s;
    font-size: 1rem;
    cursor: pointer;
    width: 100%
}

.submit-email:hover {
    color: #000;
    background-color: #c9fa3d
}

.hero-pages {
    width: 100%;
    height: 41rem;
    background-position: center;
    position: relative
}

.hero-pages__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .92)
}

.hero-pages__text {
    display: flex;
    z-index: 3;
    position: relative;
    width: 100%;
    height: 41rem;
    justify-content: center
}

.hero-pages__text h3 {
    font-size: 3.6rem;
    margin-bottom: .5rem
}

.hero-pages__text p {
    font-size: 1.6rem;
    font-weight: 600
}

.hero-pages__text p a {
    color: #010103
}

.about-main {
    margin: 10rem auto;
    display: flex;
    gap: 5rem;
    max-width: 90rem;
    align-items: center
}

.about-main__img {
    width: 43rem;
    height: 43rem
}

@media (max-width:960px) {
    .about-main {
        display: grid;
        grid-template-columns: 1fr;
        text-align: center;
        max-width: 49rem
    }

    .about-main__img {
        margin: 0 auto
    }
}

.about-main__text {
    display: flex
}

.about-main__text h3 {
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 1rem
}

.about-main__text h2 {
    font-size: 4.2rem;
    line-height: 1.2
}

.about-main__text p {
    font-size: 1.6rem;
    color: #706f7b;
    line-height: 1.5;
    margin-top: 2rem;
    margin-bottom: 4rem
}

.contact-div__form form label b, .contact-div__text a:hover {
    color: #1a1a1a
}

.about-main__text__icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem
}

.about-main__text__icons__box, .contact-div__form, .contact-div__form form {
    display: flex;
    flex-direction: column
}

.about-main__text__icons__box .last-fk {
    width: 5rem
}

.about-main__text__icons__box img {
    width: 7rem
}

.about-main__text__icons__box span {
    display: flex;
    align-items: center;
    gap: 1rem
}

@media (max-width:520px) {
    .about-main {
        max-width: 100%
    }

    .about-main__img {
        width: 100%;
        height: auto
    }

    .about-main__text__icons {
        grid-template-columns: 1fr;
        margin: 0 auto;
        gap: 0
    }

    .about-main__text__icons p {
        margin-top: 0
    }

    .about-main__text__icons__box {
        align-items: center
    }

    .about-main__text__icons__box span {
        text-align: center;
        flex-direction: column
    }
}

.about-main__text__icons__box span h4 {
    font-size: 4.6rem
}

.book-banner {
    display: flex;
    width: 100%;
    height: 20rem;
    background-image: url("/src/images/banners/book-banner.png");
    position: relative
}

.book-banner__overlay {
    background-color: #2d2d2d;
    opacity: .89;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0
}

.text-content {
    color: #fff;
    z-index: 5;
    position: relative;
    width: 100%;
    height: 20rem;
    display: flex;
    align-items: center;
    gap: 5rem
}

@media (max-width:750px) {
    .book-banner {
        height: -moz-fit-content;
        height: fit-content;
        padding: 5rem 0
    }

    .text-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        text-align: center
    }
}

.text-content h2 {
    font-size: 3rem
}

.text-content span a {
    color: #000;
    text-decoration: none
}

.text-content span {
    display: flex;
    font-size: 2.7rem;
    gap: 1rem;
    align-items: center;
    color: #1a1a1a;
    white-space: nowrap
}

.models-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 3rem;
    align-items: center;
    text-align: center;
    padding: 10rem 0;
    width: 110rem;
    margin: 0 auto
}

.models-div__box {
    border: 1px solid #d5d5d5;
    border-radius: .3rem;
    display: flex;
    width: 35rem;
    flex-direction: column
}

.models-div__box__img {
    width: 100%;
    height: auto;
    border-radius: .3rem
}

.models-div__box__img img {
    width: 100%;
    height: 27rem
}

.models-div__box__descr {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    color: #010103
}

.models-div__box__descr__name-price {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.models-div__box__descr__name-price__name {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: .5rem
}

.models-div__box__descr__name-price__name p {
    font-size: 2.4rem;
    font-weight: 700
}

.models-div__box__descr__name-price__name span {
    display: flex;
    gap: .4rem
}

.models-div__box__descr__name-price__name span i {
    font-size: 1.4rem;
    color: #ffc933
}

.contact-div, .models-div__box__descr__name-price__details span i {
    color: #010103
}

.models-div__box__descr__name-price__price {
    display: flex;
    flex-direction: column;
    text-align: right
}

.models-div__box__descr__name-price__price h4 {
    font-size: 2.8rem
}

.models-div__box__descr__name-price__price p {
    font-size: 1.6rem
}

.models-div__box__descr__name-price__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    -moz-column-gap: 7rem;
    column-gap: 7rem;
    margin: 2rem auto;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid #c6c6c6
}

.models-div__box__descr__name-price__details span {
    font-size: 1.8rem;
    font-weight: 500;
    color: #777;
    text-align: left
}

.models-div__box__descr__name-price__btn {
    background-color: #1a1a1a;
    padding: 1.8rem 3rem;
    border-radius: .3rem;
    transition: .3s;
    border: 2px solid #1a1a1a;
    font-size: 1.8rem;
    cursor: pointer
}

.models-div__box__descr__name-price__btn:hover {
    box-shadow: 0 10px 15px 0 rgba(255, 83, 48, .6);
    background-color: #fa4226
}

.models-div__box__descr__name-price__btn a {
    text-decoration: none;
    color: #fff;
    font-weight: 700
}

.team-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 4rem;
    align-items: center;
    text-align: center;
    padding: 10rem 2rem;
    width: 110rem;
    margin: 0 auto
}

@media (max-width:1150px) {
    .models-div, .team-container {
        grid-template-columns: 1fr 1fr;
        width: -moz-fit-content;
        width: fit-content
    }
}

@media (max-width:800px) {
    .bg-shape, .hero-content__car-img {
        display: none
    }

    .hero-content {
        justify-content: center
    }

    .hero-content__text {
        text-align: center;
        align-items: center
    }

    .booking-modal {
        width: 100%
    }

    .plan-container__boxes {
        grid-template-columns: 1fr;
        margin-top: 1rem
    }

    .models-div, .team-container {
        grid-template-columns: 1fr;
        width: -moz-fit-content;
        width: fit-content
    }
}

.team-container__box {
    width: 33rem;
    background-color: #fff;
    box-shadow: 0 20px 10px 0 rgba(0, 0, 0, .08)
}

@media (max-width:400px) {
    .book-content__box {
        padding: 4rem 2.5rem 5rem
    }

    .models-div__box {
        grid-template-columns: 1fr;
        width: 100%
    }

    .team-container__box {
        width: 100%
    }
}

.team-container__box__img-div {
    width: 100%;
    height: auto;
    background-color: #f6f6f6
}

.team-container__box__img-div img {
    width: 100%
}

.team-container__box__descr {
    color: #010103;
    padding: 3rem
}

.team-container__box__descr h3 {
    font-size: 2.2rem
}

.team-container__box__descr p {
    font-size: 1.6rem;
    font-weight: 500;
    color: #777
}

.contact-div {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    gap: 3rem;
    margin: 0 auto;
    padding: 0 2rem 5rem;
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat
}

.contact-div__text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center
}

@media (max-width:950px) {
    .contact-div {
        grid-template-columns: 1fr;
        text-align: center
    }

    .contact-div__text {
        margin: 0 auto 2rem
    }
}

.contact-div__text h2 {
    font-size: 3rem;
    line-height: 1.3
}

.contact-div__text p {
    font-size: 1rem;
    color: #706f7b;
    line-height: 1.6;
    margin-bottom: 1rem
}

.contact-div__text a {
    color: #010103;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: .5rem
}

.yellow-box h2, .yellow-box p {
    font-family: Poppins, sans-serif;
    margin: .5rem 0 1rem
}

.contact-div__form form label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem
}

.contact-div__form form input, .contact-div__form form textarea {
    background-color: #f2f2f2;
    padding: 19px 30px;
    font-size: 1rem;
    outline: 0;
    border: none
}

.contact-div__form form input {
    margin-bottom: 2.3rem
}

.contact-div__form form textarea {
    height: 10rem;
    margin-bottom: 2.5rem
}

.contact-div__form form button {
    margin: 10px;
    padding: 1rem 2rem;
    height: -moz-fit-content;
    height: fit-content;
    align-self: self-end;
    border: none;
    display: inline-block;
    border-radius: 5px;
    font-weight: 600;
    color: #fff;
    background-color: #333;
    cursor: pointer;
    transition: .3s;
    width: 100%
}

.contact-div__form form button:hover {
    background-color: #c9fa3d;
    color: #333
}

.our-service-slider {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative
}

.custom-arrow, .yellow-box {
    position: absolute;
    top: 50%
}

.yellow-box {
    width: 700px;
    background-color: #fec601;
    padding: 40px;
    text-align: center
}

.yellow-box h2 {
    font-size: 2.2rem;
    color: #fff
}

.yellow-box p {
    font-size: 1.2rem
}

.custom-arrow {
    transform: translateY(-50%);
    cursor: pointer;
    background-color: rgba(255, 255, 255, .7);
    border-radius: 50%;
    padding: 10px;
    z-index: 1
}

.custom-prev {
    left: 20px
}

.custom-next {
    right: 20px
}

.client-feedback {
    position: relative
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    pointer-events: none;
    z-index: 999;
    width: 221px;
    height: 61px;
    pointer-events: none !important
}


  /* Mobile styles */
  .hamburger {
    display: none !important;
    flex-direction: column !important;
    cursor: pointer !important;
    margin-right: 10px;
  }
  
  .hamburger div {
    width: 25px !important;
    height: 3px !important;
    background-color: black !important;
    margin: 4px 0 !important;
    transition: 0.4s !important;
  }
  
  .mobile-menu {
    display: none !important;
    flex-direction: column !important;
    align-items: center !important;
    position: absolute !important;
    top: 76px !important;
    left: 0 !important;
    width: 100% !important;
    list-style: none !important;
    z-index: 1200 !important;
    position: fixed !important;
background: rgba(253, 195, 35, 0.88);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(8.3px);
-webkit-backdrop-filter: blur(8.3px);
  }
  
  .mobile-menu li {
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid rgba(36, 36, 36, 0.127);
    text-align: center;
  }

  .mobile-menu li a{
    color: #000000;
    text-decoration: none;
  }
  
  /* Show mobile menu when active */
  .mobile-menu.active {
    display: flex !important;
   
  }
  .css-1jqq78o-placeholder {
    grid-area: 1 / 1 / 2 / 3;
    color: hsl(0, 0%, 100%) !important;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
}
  /* Media query for mobile */
  @media (max-width: 768px) {
    .navbar__links {
      display: none !important;
    }
    
    .hamburger {
      display: flex !important;
    }
  }
  

  .css-1dimb5e-singleValue {
    grid-area: 1 / 1 / 2 / 3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: hsl(0, 0%, 100%) !important;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
}

.top-bar{
    display:flex;
    background-color: #fec601;
    justify-content: center;
padding: .3rem 1rem;
    font-weight: 500;
}
.top-bar a{
    color:#000000;
    text-decoration: none;
}
