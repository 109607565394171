* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


@import "react-quill/dist/quill.snow.css";


html {
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;


}


.section-tp-bt {
  margin: 10rem 0rem;

}




.hero-section-bg-2 {
  position: relative;
  background-image: url('../images/hero/hero-11.webp');
  background-size: cover;
  background-position: center;
  padding: 50px 0;
  /* Adjust padding as needed */
  height: 100vh;
}




.VehicleCard-main {

  padding: 0px 50px;
}





.hero-text-2 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10rem;
}

.hero-text-2 h1 {
  text-align: center;
  font-size: 4rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
}

.hero-text-img {
  width: 100px;
  height: 100%;
  object-fit: cover;
  object-position: center;

  top: 0;
  left: 0;
  margin: -10px 16px;


}

.hero-text h2 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;

}

.hero-text p {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 10px;


}

.reserve-now-2 {
  text-align: center;
  margin-top: 20px;
  /* Adjust margin as needed */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}


.reserve-now-btn {
  display: inline-block;
  border-radius: 15px;
  border: none;
  background: #FDC323;
  color: rgb(0, 0, 0);
  font-family: inherit;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  box-shadow: 0px 14px 56px -11px #FDC323;
  width: 11em;
  padding: 13px 10px;
  transition: all 0.4s;
  cursor: pointer;
  text-decoration: none;
}



.hero-btn-flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 2rem;
}

/* call now button  */

.call-now-btn-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;

}

.call-now-btn-content {
  display: flex;
  align-items: center;
  padding: 5px 40px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 25px;
  background: #FDC323;
  transition: 1s;
  border-radius: 100px;
  cursor: pointer;

}

.call-now-btn-content:hover,
.call-now-btn-content:focus {
  transition: 0.5s;
  -webkit-animation: call-now-btn-content 1s;
  animation: call-now-btn-content 1s;
  outline: 0.1em solid transparent;
  outline-offset: 0.2em;
  box-shadow: 0 0 0.4em 0 #c9fa3d;
  background-color: #c9fa3d;
}

.call-now-btn-content .call-now-icon-arrow {
  transition: 0.5s;
  margin-right: 0px;
  transform: scale(0.6);
}

.call-now-btn-content:hover .call-now-icon-arrow {
  transition: 0.5s;
  margin-right: 25px;
}

.call-now-icon-arrow {
  width: 20px;
  margin-left: 15px;
  position: relative;
  top: 6%;
}

.call-now-btn-title {
  color: #2d2d2d !important;
  font-weight: 600 !important;
}

/* Rest of the CSS remains unchanged */

/* SVG animations */
@keyframes color_anim {
  0% {
    fill: white;
  }

  50% {
    fill: #FDC323;
  }

  100% {
    fill: white;
  }
}

/* Button animations */
@-webkit-keyframes call-now-btn-content {
  0% {
    outline: 0.2em solid #FDC323;
    outline-offset: 0;
  }
}

@keyframes call-now-btn-content {
  0% {
    outline: 0.2em solid #FDC323;
    outline-offset: 0;
  }
}

.call-new-btn {

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background-color: rgb(161, 255, 20);
  border-radius: 30px;
  color: rgb(19, 19, 19);
  font-weight: 600;
  border: none;
  position: relative;
  cursor: pointer;
  transition-duration: 0.2s;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.116);
  font-size: 2.7rem;
  padding: 10px 20px;
  transition-duration: 0.5s;
  background-color: #fec601;
  padding: 15px 25px;
  border-radius: 100px;
}

.phone-icon {
  height: 25px;
  transition-duration: 1.5s;
}

.call-new-btn:hover {
  background-color: #c9fa3d;
  transition-duration: 0.5s;
}

.call-new-btn:active {
  transform: scale(0.97);
  transition-duration: 0.2s;
}

.call-new-btn:hover .phone-icon {
  transform: rotate(250deg);
  transition-duration: 1.5s;
}




.per-day-price {
  font-size: 12px;
  color: #e3e1e1;
  margin: 30px 0;

}



.per-day-price span:nth-child(2) {
  font-size: 25px;
  font-weight: 600;
  color: #FDC323;
}


.hidden-car {
  transform: scale(0.7);
  transition: transform 0.3s ease;
}


/* NotFoundPage.css */
.not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.not-found-content {
  text-align: center;
}

.not-found-icon {
  font-size: 4rem;
  margin-bottom: 20px;
}

button.wh-ap-btn {
  outline: none;
  width: 60px;
  height: 60px;
  border: 0;
  background-color: #2ecc71;
  padding: 0;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  transition: opacity 0.3s, background 0.3s, box-shadow 0.3s;
}

button.wh-ap-btn::after {
  content: "";
  background-image: url("//i.imgur.com/cAS6qqn.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 60%;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 1;
}

button.wh-ap-btn:hover {
  opacity: 1;
  background-color: #20bf6b;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}


.wh-api-bg {
  background-color: #fec601;
}

.wh-api {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
}

.wh-fixed {
  margin-right: 15px;
  margin-bottom: 15px;
}

.wh-fixed>a {
  display: block;
  text-decoration: none;
}

button.wh-ap-btn::before {
  content: "Chat with me";
  display: block;
  position: absolute;
  margin-left: -130px;
  margin-top: 16px;
  height: 25px;
  background: #49654e;
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  border-radius: 3px;
  width: 0;
  opacity: 0;
  padding: 0;
  transition: opacity 0.4s, width 0.4s, padding 0.5s;
  padding-top: 7px;
  border-radius: 30px;
  box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
}

.wh-fixed>a:hover button.wh-ap-btn::before {
  opacity: 1;
  width: auto;
  padding-top: 7px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100px;
}

/* animacion pulse */

.whatsapp-pulse {
  width: 60px;
  height: 60px;
  right: 10px;
  bottom: 10px;
  background: #10b418;
  position: fixed;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border-radius: 50%;
  z-index: 9999;
  display: inline-block;
  line-height: 65px;
}

.whatsapp-pulse:before {
  position: absolute;
  content: " ";
  z-index: -1;
  bottom: -15px;
  right: -15px;
  background-color: #10b418;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  opacity: 0.6;
  -webkit-animation: pulse 1s ease-out;
  animation: pulse 1.8s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.3);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.6);
    opacity: 0.6;
  }

  75% {
    -webkit-transform: scale(0.9);
    opacity: 0.3;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0.3);
    opacity: 1;
  }

  50% {
    transform: scale(0.6);
    opacity: 0.6;
  }

  75% {
    transform: scale(0.9);
    opacity: 0.3;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}


.social-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 25px;
  gap: 40px;
}

/* for all social containers*/
.socialContainer {
  width: 40px;
  height: 40px;
  background-color: rgb(44, 44, 44);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-duration: .3s;
  border-radius: 50%;
}

/* instagram*/
.containerOne:hover {
  background-color: #d62976;
  transition-duration: .3s;
}

/* twitter*/
.containerTwo:hover {
  background-color: #00acee;
  transition-duration: .3s;
}

/* linkdin*/
.containerThree:hover {
  background-color: #0072b1;
  transition-duration: .3s;
}

/* Whatsapp*/
.containerFour:hover {
  background-color: #128C7E;
  transition-duration: .3s;
}

.socialContainer:active {
  transform: scale(0.9);
  transition-duration: .3s;
}

.socialSvg {
  width: 17px;
}

.socialSvg path {
  fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
  animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


.scroll-up-button {
  position: fixed;
  bottom: 100px;
  right: 35px;
}

.scroll-up-button button {
  background-color: #007bff63;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.scroll-up-button button:hover {
  background-color: #007bff;
}





.category-main-2 {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}


.category-main-2 .category-2:nth-child(1) {
  border-right: 1px solid#fff;
}
.category-main-2 .category-2:nth-child(2) {
  border-right: 1px solid#fff;
}
.category-main-2 .category-2:nth-child(3) {
  border-right: 1px solid#fff;
}



.category-2 {
  /*margin: 0 10px;*/
  padding: 9px 20px;
  color: #E6F4F6;
  font-size: 1.5rem;
}




.red-text {
  color: red;
}

.yellow-text {
  color: #fdc323;
}

.btmxSM {
  position: relative;
  transition: background-color 0.1s ease 0s;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  padding: 24px;
  background-color: rgba(17, 17, 17, 0.05);
  border: 1px solid transparent;
  min-height: 308px;
}


.VehicleCard-slider-2 {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0px 50px;

}




.slick-slide div div {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
}


.vehicleCard {
  position: relative;
  width: 300px;
   height: 250px;
  /* background-color: #f2f2f2; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  perspective: 1000px;
  /* box-shadow: 0 0 0 5px #ffffff80; */
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin: 10px;
}


.vehicleCard-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale(0.9);
}


.vehicleCard:hover {
  transform: scale(1.1);
  /* box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2); */


}

.vehicleCard-price {
  display: flex;
  position: relative;
  bottom: 10px;
}

.card__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  transform: rotateX(-90deg);
  transform-origin: bottom;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.vehicleCard:hover .card__content {
  transform: rotateX(0deg);
}

.card__title {
  margin: 0;
  font-size: 20px;
  color: #f3f3f3;
  font-weight: 600;
}



.card__description {
  margin: 10px 0 0;
  font-size: 14px;
  color: #777;
  line-height: 1.4;
}

.slick-prev:before {
  content: '←';
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 40px !important;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 999;
}


.slick-prev {
  left: 0px !important;
  z-index: 999;

}

.slick-next {
  right: 0px !important;
  z-index: 999;
}


.card-table {
  width: 100%;
}

.card-table p {

  padding: 5px 10px;
  border: 1px solid #cbcbcb;
  border-radius: 5px;

}



/* General styles for all devices */



.rotate-diagonal-2 {
  animation: rotate-diagonal-2 0.4s ease-in both;
}


.banner-content-second-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.footer-bottom a {
  text-decoration: none;
}



/* about us page */
.about-us {
  padding: 100px 20px !important;
  text-align: center;
}


.about-us-container {
  padding: 40px;
}

.about-us-image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.about-us-image img {
  max-width: 100%;
  height: 500px;
  display: block;
}


.about-us-paragraph h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  font-family: Rubik, sans-serif;
}

.about-us-paragraph h3 {
  margin: 30px 0 10px 0;
}

.about-us-paragraph p {
  line-height: 1.8;
  margin-top: 10px;
  font-size: 1.2rem;
  font-family: Rubik, sans-serif;
  margin: 20px 120px;
}

.blog-card {
  width: 25rem;
  background: #e8e8e8;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: none !important;
  cursor: pointer;
}

.blog-card:hover {
  background: #ff0;
  border-radius: 8px;

}


.blog-card .blog-card-image {
  transition: transform 0.3s ease;
}

.blog-card:hover .blog-card-image {
  transform: scale(1.05);
}

.blog-card-title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #000000 !important;
  margin-bottom: 10px !important;
}



.blog-card-image {
  height: 200px;
  object-fit: cover;
  background-color: #c9c9c9;
  border-radius: 8px;

}

.blog-card-content {
  padding: 15px !important;
}

.blog-card-body {
 color: #6e6e6e;
}

.blog-card-body :hover{
  color: #000;
}


.blog-card-by-name {
  font-weight: 700;
}

.blog-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  gap: 20px;

}

.blog-title {
  text-align: center;
  margin: 30px;
}

.blog-card-footer {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.back-button {
  background-color: #2e54a7;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}





.blog-detail h1 {
  font-size: 2em;
  margin-bottom: 10px;
  text-transform: capitalize;
  text-align: center;
}

.blog-detail .blog-create-date {
  color: #666;
  margin-bottom: 20px;
  text-align: end;
}

.blog-detail img {
  width: 100%;
  height: 20vmax;
  margin-bottom: 20px;
  object-fit: contain;
}

.blog-detail .blog-content {
  font-size: 1.1em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 28px;
  
}

.small-left-aligned-text {
  text-align: right;
  font-size: 0.875rem !important;  
  color: #b2b2b2;
}

.loading-progress{
  width: 250px;
  /*height: 100px;*/
}

.loading-progress img{
  width: 100%;
  height:  100%;
  object-fit: contain;
}
@media only screen and (max-width: 768px) {
  .info-list li { margin: 1.5rem 0; font-size: 1.3rem; }

  .blog-detail h1 {
    font-size: 25px;
    margin-bottom: 10px;
    text-transform: capitalize;
    text-align: center;
}

  .blog-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    gap: 20px;
}

  .about-us-paragraph p {
    font-size: 1rem;
    line-height: 1.8;
    margin: 20px 5px;
  }

  .about-us-paragraph h3 {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }

  .about-us-paragraph h1 {
    font-size: 2.3rem;
    margin-bottom: 20px;
  }

  .about-us {
    padding: 100px 0px !important;

  }

  .about-us-container {
    padding: 0px;
  }

  .about-us-image img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .about-us-image, .about-us-paragraph {
    flex: 100%;
  }


  .image-slider img {
    height: 500px !important;
  }


  .book-car-title span {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    padding: 0px 50px 10px 50px !important;
    text-align: center !important;
    color: #090808 !important;
    background-color: #FDC323 !important;
    border-radius: 0px 0px 40px 40px !important;

  }

  .book-content__box {
    padding: 40px 20px !important;
  }

  .contact-div__text h2 {
    font-size: 2rem !important;

  }

  .contact-div__text {
    display: flex !important;
    flex-direction: column !important;
    gap: 1rem !important;
    max-width: 100% !important;
  }


  .call-new-btn {
    font-size: 2rem !important;
  }

  .hero-text {
    padding-top: 7rem !important;
  }

  .hero-text h1 {
    font-size: 2.5rem !important;
  }

  .hero-text h2 {
    font-size: 1.5rem !important;
  }

  .hero-text p {
    font-size: 1rem !important;
  }

  .banner-section {
    padding: 5rem 0rem !important;
  }

  .call-now-btn-content {
    font-size: 20px !important;
  }

  .call-now-btn-content .call-now-icon-arrow {
    transition: 0.5s !important;
    margin-right: 0px !important;
    transform: scale(0.4) !important;
  }

  .book-content-bg {
    padding: 6rem 1rem 5rem 1rem !important;

  }

  .plan-container__boxes {

    padding: 0 0rem !important;
  }

  .yellow-box {
    width: 100% !important;
    z-index: 99 !important;
  }

  .pick-section {
    padding: 3rem 0 !important;
  }

  .faq-section {

    padding: 3rem 0 !important;

  }

  .jOACPc {
    margin-bottom: 0px !important;
  }

  .faq-box__question {

    padding: 1.2rem 2rem !important;

  }

  .faq-box__question p {
    font-size: 1rem !important;

  }

  .contact-div {
    overflow: hidden;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    gap: 3rem;
    margin: 0 auto;
    color: #010103;
    padding: 0rem 2rem 5rem 2rem;
    background-image: url(http://localhost:3001/static/media/bg-contact.c178c6d….png);
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    align-content: center;
    justify-content: center;
    justify-items: stretch;
  }

  .social-card {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 25px;
    gap: 30px;
  }

  footer {
    background-color: #fec601;
    padding: 100px 0px !important;
  }

  .footer-content {
    display: grid;
    color: #2d2d2d;
    gap: 2rem;
    text-align: center;
    grid-template-columns: none !important;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  .container {
    max-width: 133rem !important;
    margin: 0 auto !important;
    padding: 0 1rem !important;
  }

  .all-questions {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-top: 3rem !important;
  }

  .text-content h2 {
    font-size: 2.5rem !important;
  }

  .hero-text-2 {
    text-align: center !important;
    margin-bottom: 20px !important;
    margin-top: 2.5rem !important;
  }

  .hero-text-2 h1 {
    text-align: center !important;
    font-size: 2.8rem !important;
    font-weight: 600 !important;
    color: #fff !important;
    margin-bottom: 10px !important;
  }

  .category-main-2 {
    display: flex !important;
    justify-content: center !important;
    margin-bottom: -20px !important;
  }

  .navbar {

    padding: 5px 5px !important;

  }

  .reserve-now-2 {
    text-align: center !important;
    margin-top: 20px !important;
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }



  .slick-prev {
    left: -32px !important;
    z-index: 999 !important;

  }

  .slick-next {
    right: -18px !important;
    z-index: 999 !important;
  }


  .category-2 {
    margin: 0 10px !important;
    padding: 9px 20px !important;
    color: #E6F4F6 !important;
    font-size: 1rem !important;
  }

  .reserve-now-btn {
    display: inline-block !important;
    border-radius: 15px !important;
    border: none !important;
    background: #FDC323 !important;
    color: rgb(0, 0, 0) !important;
    font-family: inherit !important;
    text-align: center !important;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    box-shadow: 0px 14px 56px -11px #FDC323 !important;
    width: 11em !important;
    padding: 12px 10px !important;
    transition: all 0.4s !important;
    cursor: pointer !important;
  }



  .banner-content-second-text p:not(:last-child)::after {
    display: none !important;
  }

  .banner-content-second-text {
    gap: 10px !important;
  }

  .navbar__img {
    width: auto !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {}


@media only screen and (min-width: 993px) and (max-width: 1200px) {}


@media only screen and (min-width: 1201px) {}


